/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable react/require-default-props */
import Link from 'next/link';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import {
  Button,
  Col,
  Layout,
  Menu,
  Row,
  Spin,
} from 'antd';
import type { MenuProps } from 'antd';
import posthog from 'posthog-js';
// import { useMutation } from '@tanstack/react-query';
import {
  ArrowLeftOutlined,
  BookOutlined,
  DashboardOutlined,
  LogoutOutlined,
  PaperClipOutlined,
  // SettingOutlined,
  // ShopOutlined,
  // TeamOutlined,
  // UsergroupDeleteOutlined,
} from '@ant-design/icons';
import LayoutBase from './LayoutBase';
// import { graphql } from '../../__generated__/gql';
// import { errorToString, graphqlClient } from '../../lib/util';
import LocaleDropdown from '../LocaleDropdown';
import useDictionary from '../../lib/useDictionary';
// import { WhoamiQuery } from '../../__generated__/gql/graphql';

interface LayoutDashboardProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  showBackButton?: boolean;
  // user?: WhoamiQuery["whoami"];
}

export default function LayoutDashboard(props: LayoutDashboardProps): JSX.Element {
  const {
    children,
    title,
    description,
    isLoading,
    showBackButton = false,
    // user,
  } = props;
  // const signOutMutation = useMutation({
  //   mutationFn: async () => graphqlClient.request(graphql(`mutation signOut { signOut }`)),
  //   onSuccess: () => { posthog.reset(); Router.push('/api/auth/logout'); },
  //   onError: (e) => message.error(errorToString(e)),
  // });
  const d = useDictionary();
  const router = useRouter();

  const items: MenuProps['items'] = [
    {
      label: (
        <Link href="/">
          {d.menu.Dashboard}
        </Link>
      ),
      key: '/',
      icon: <DashboardOutlined />,
    },
    {
      label: (
        <Link href="/programs">
          {d.menu.Programs}
        </Link>
      ),
      key: `/programs`,
      icon: <PaperClipOutlined />,
    },
    {
      label: (
        <Link href="/enrollments">
          {d.menu.Enrollments}
        </Link>
      ),
      key: `/enrollments`,
      icon: <BookOutlined />,
    },
    // {
    //   label: (
    //     <Link href={`/tenant/${tenantId}/programs`}>
    //       Programs
    //     </Link>
    //   ),
    //   key: `/tenant/${tenantId}/programs`,
    //   icon: <PaperClipOutlined />,
    // },
    // {
    //   label: (
    //     <Link href="/settings">
    //       {d.menu.Settings}
    //     </Link>
    //   ),
    //   key: '/settings',
    //   icon: <SettingOutlined />,
    // },
    // {
    //   label: 'Grouping',
    //   key: 'tenant',
    //   icon: <SettingOutlined />,
    //   children: [
    //     {
    //       label: 'Relationships',
    //       key: 'staff',
    //     },
    //     {
    //       label: 'Settings',
    //       key: 'settings',
    //     },
    //     // {
    //     //   label: (
    //     //     <Link href="/">
    //     //       Gamma
    //     //     </Link>
    //     //   ),
    //     //   key: 'switch',
    //     // },
    //   ],
    // },
    {
      label: d.menu.SignOut,
      key: 'signOut',
      icon: <LogoutOutlined />,
    },
  ];

  const handleSignOut = async () => {
    await signOut({ callbackUrl: '/sign-in' });
    posthog.reset();
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'signOut') handleSignOut();
  };

  return (
    <LayoutBase title={title} description={description}>
      <Layout style={{ minHeight: '100vh' }}>
        <Layout.Sider
          breakpoint="lg"
          collapsedWidth="0"
          theme="light"
          // theme="dark"
        >
          <Menu
            theme="light"
            // theme="dark"
            // mode="vertical"
            mode="inline"
            // mode="horizontal"
            onClick={handleMenuClick}
            items={items}
            defaultSelectedKeys={[router.asPath]}
          />
        </Layout.Sider>
        <Layout>
          <Layout.Content style={{ margin: 16 }}>
            <Row justify="space-between" style={{ marginBottom: 8 }}>
              {showBackButton && (
                <Col>
                  <Button
                    onClick={() => router.back()}
                    icon={<ArrowLeftOutlined />}
                  >
                    Back
                  </Button>
                </Col>
              )}
              <Col>
                <LocaleDropdown />
              </Col>
            </Row>
            <Spin spinning={!!isLoading} style={{ width: '100%' }}>
              {children}
            </Spin>
          </Layout.Content>
          <Layout.Footer style={{ textAlign: 'center', color: 'rgba(0,0,0,.45)', padding: 24 }}>
            { /* eslint-disable-next-line react/jsx-one-expression-per-line */ }
            <div>Copyright {new Date().getFullYear()} Curacubby</div>
          </Layout.Footer>
        </Layout>
      </Layout>
    </LayoutBase>
  );
}
