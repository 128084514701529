/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import { useCallback } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { useRouter } from 'next/router';

type Locale = 'en' | 'es' | 'vi';
// TODO: auto redirect and NEXT_LOCALE cookie funcationality. replace outdated overlay

// eslint-disable-next-line react/require-default-props
// export default function LocaleDropdown({ style }: { style?: React.CSSProperties }) {
export default function LocaleDropdown() {
  const router = useRouter();
  const locale: Locale = router.locale?.match(/en|es|vi/) ? router.locale as Locale : 'en';

  const languageNativeNames = {
    en: 'English',
    es: 'Español',
    vi: 'Tiếng Việt',
  };
  const localeLang = languageNativeNames[locale || 'en'];
  const languageToFlagCode = {
    en: 'US',
    es: 'MX',
    vi: 'VN',
  };
  const localeFlagCode = languageToFlagCode[locale || 'en'];

  return (
    <Dropdown
      overlay={(
        <Menu
          onClick={(info) => router.push(router.asPath, router.asPath, { locale: info.key })}
          selectedKeys={[locale || 'en']}
        >
          <Menu.Item key="en">
            <img alt="United States" src="https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" width="20px" height="20px" style={{ marginRight: 5 }} />
            English
          </Menu.Item>
          <Menu.Item key="es">
            <img alt="Mexico" src="https://purecatamphetamine.github.io/country-flag-icons/3x2/MX.svg" width="20px" height="20px" style={{ marginRight: 5 }} />
            Español
          </Menu.Item>
          <Menu.Item key="vi">
            <img alt="Vietnam" src="https://purecatamphetamine.github.io/country-flag-icons/3x2/VN.svg" width="20px" height="20px" style={{ marginRight: 5 }} />
            Tiếng Việt
          </Menu.Item>
        </Menu>
      )}
    >
      <Button>
        <img
          alt="Language Flag"
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${localeFlagCode}.svg`}
          width="20px"
          height="20px"
          style={{ marginRight: '5px' }}
        />
        {localeLang}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}
